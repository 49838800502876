import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DateSearchComponent } from '../../core/component/date-search/date-search.component';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterType } from '../../core/filter/filterType';
import { PagedModel } from '../../core/filter/paged-model';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { DatexPipe } from '../../core/pipe/datex.pipe';
import { AuftragspositionType } from '../../enumeration/auftragsposition-type.enum';
import { Auftrag } from '../../model/auftrag';
import { DateValidation } from '../../model/dateValidation';
import { Farm } from '../../model/farm';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { FarmService } from '../../service/farm/farm.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { Utils } from '../../utils/utils';
import { SearchFieldDefinition } from '../search/search-field-definition';
import { FarmDetailModalComponent } from './farm-detail-modal/farm-detail-modal.component';

@Component({
  selector: 'portal-farmuebersicht',
  templateUrl: './farmuebersicht.component.html',
  styleUrls: ['./farmuebersicht.component.scss']
})
export class FarmuebersichtComponent extends AbstractServerPaginationTable<Farm> implements OnInit, OnDestroy {
  sortField = [
    {
      prop: 'oldShortNumber',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  @Input() public selectionOnlyMode = false;

  @Output() public selection = new EventEmitter<Farm>();

  auftrag: Auftrag;

  selectedAuftragsdatum: DateValidation;

  isBesamungstechniker: boolean;

  routeSubscription: Subscription;

  editAuftrag: false;

  leistungsprotokollLandwirt: false;

  @ViewChild(DateSearchComponent, { static: true }) dateSearch: DateSearchComponent;

  constructor(
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private readonly farmService: FarmService,
    private datexPipe: DatexPipe,
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly modaKeycloakService: ModaKeycloakService
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Click auf Abbrechen.
   * @param $event Mouseevent
   */
  public abbrechen($event: MouseEvent) {
    this.router.navigate(['/auftraege']);
  }

  /**
   * Sortierung der Felder.
   * @param event Event
   */
  determineSortField(event): string {
    switch (event.column.prop) {
      case 'name':
        return 'adresse.nachname';
      case 'oldShortNumber':
        return 'oldShortNumber';
      case 'betriebsschluessel':
        return 'betriebsschluessel';
      default:
        return event.column.prop;
    }
  }

  /**
   * Updatet die Tabelle.
   *
   * @param data PageData
   */
  doAfterLoadData(data: PagedModel<Farm>) {
    this.onSelect(null);
    this.calculateData(data);
    this.updatePage(data);
    this.tableIsLoading = false;
    if (data.content.length === 1) {
      this.selectedElement = data.content[0];
      if (!this.editAuftrag) {
        this.weiter();
      }
    }
  }

  /**
   *  {@inheritDoc}.
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    if (this.isBesamungstechniker) {
      this.loadDataSubscription = this.farmService.loadDataForUser(searchFilterPagingResource).subscribe(
        (data) => {
          this.doAfterLoadData(data);
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    } else {
      this.loadDataSubscription = this.farmService.loadData(searchFilterPagingResource).subscribe(
        (data) => {
          this.doAfterLoadData(data);
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    }
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('oldShortNumber', FilterType.STRING),
      new SearchFieldDefinition('betriebsschluessel', FilterType.STRING),
      new SearchFieldDefinition('nachname', FilterType.STRING),
      new SearchFieldDefinition('vorname', FilterType.STRING),
      new SearchFieldDefinition('strasseHausnummer', FilterType.STRING),
      new SearchFieldDefinition('ort', FilterType.STRING),
      new SearchFieldDefinition('shortAddress', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  public getService(): ServerPaginationService<Farm> {
    return this.farmService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return [
      {
        prop: 'oldShortNumber',
        dir: SortDirection.ASC.frontendkuerzel
      },
      {
        prop: 'betriebsschluessel',
        dir: SortDirection.ASC.frontendkuerzel
      }
    ];
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.routeSubscription);
  }

  ngOnInit() {
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.auftrag = JSON.parse(sessionStorage.getItem('auftrag'));
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
    this.selectedAuftragsdatum = new DateValidation(moment(), true);
    this.routeSubscription = this.route.data.subscribe((value) => {
      this.editAuftrag = value.editAuftrag;
      this.leistungsprotokollLandwirt = value.leistungsprotokollLandwirt;
    });
  }

  /**
   * {@inheritDoc}
   */
  onDoubleClick(row: Farm) {
    if (!this.editAuftrag) {
      this.weiter();
    }
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row Farm
   */
  rowIdentity(row: Farm) {
    return row.id;
  }

  /**
   * Oeffnet den Detaildialog.
   * @param farm Betrieb.
   */
  showBetriebDetails(farm: Farm) {
    const dialogRef = this.dialog.open(FarmDetailModalComponent, {
      minWidth: '1261px',
      panelClass: 'vost-modal-dialog',
      data: {
        betriebsschluessel: farm.betriebsschluessel
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const currentTechniker = this.modaKeycloakService.getTechnikerKey();
        const techniker = this.selectedElement.technikerDetails.filter(
          (td) => td.technikerKey.aiStationNumber === currentTechniker.aiStationNumber && td.technikerKey.number === currentTechniker.number
        );
        if (techniker && techniker.length) {
          techniker[0].suchfokus = result;
        }
      }
    });
  }

  /**
   * Weiter Button.
   */
  weiter() {
    if (this.selectionOnlyMode) {
      this.selection.emit(this.selectedElement);
    } else {
      if (this.leistungsprotokollLandwirt) {
        if (this.selected.length > 0) {
          sessionStorage.setItem('selectedFarm', JSON.stringify(this.selected[0]));
        } else {
          sessionStorage.setItem('selectedFarm', JSON.stringify(this.selectedElement));
        }
        this.router.navigate(['leistungsprotokollLandwirt']);
      } else {
        if (this.selectedAuftragsdatum.date !== null && this.selectedAuftragsdatum.isValid) {
          if (this.auftrag && this.auftrag.betriebsschluessel !== this.selectedElement.betriebsschluessel) {
            this.auftrag.positionen = this.auftrag.positionen.filter((position) => position.type !== AuftragspositionType.BESAMUNG);
          }
          this.auftrag.betriebsschluessel = this.selectedElement.betriebsschluessel;
          this.auftrag.adresse = this.selectedElement.adresse;
          this.auftrag.auftragsdatum = this.selectedAuftragsdatum.date;
          const currentTechniker = this.modaKeycloakService.getTechnikerKey();
          const techniker = this.selectedElement.technikerDetails.filter(
            (td) => td.technikerKey.aiStationNumber === currentTechniker.aiStationNumber && td.technikerKey.number === currentTechniker.number
          );
          if (techniker && techniker.length) {
            this.auftrag.suchfokus = techniker[0].suchfokus;
          }
          this.auftrag.shortNumber = this.selectedElement.oldShortNumber;
          this.auftrag.farmCategory = this.selectedElement.farmCategory;
          sessionStorage.setItem('auftrag', JSON.stringify(this.auftrag));
          this.router.navigate(['auftraege', 'farmuebersicht', 'auftragsuebersicht']);
        }
      }
    }
  }
}
