import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractDialog } from '../../core/component/dialog/abstract-dialog';
import { FilterType } from '../../core/filter/filterType';
import { PagedModel } from '../../core/filter/paged-model';
import { SortDirection } from '../../core/filter/sort-direction';
import { Inseminator } from '../../model/Inseminator';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { InseminatorService } from '../../service/inseminator/inseminator.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SearchFieldDefinition } from '../search/search-field-definition';
import { TauschauswahlComponent } from '../tauschuebersicht/tauschauswahl/tauschauswahl.component';

@Component({
  selector: 'portal-technikeruebersicht-modal',
  templateUrl: './technikeruebersicht-modal.component.html',
  styleUrls: ['./technikeruebersicht-modal.component.scss']
})
export class TechnikeruebersichtModalComponent extends AbstractDialog<Inseminator> implements OnInit {
  sortField = [
    {
      prop: 'technikerKey.number',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  isBesamungstechniker: boolean;

  @ViewChild('searchTechnikerField', { static: true }) searchTechnikerField: any;

  constructor(
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private readonly inseminatorService: InseminatorService,
    private readonly modaKeycloakService: ModaKeycloakService,
    private readonly dialogRef: MatDialogRef<TauschauswahlComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Bestaetigen Button.
   */
  bestaetigen() {
    this.dialogRef.close(this.selectedElement);
  }

  /**
   * Updatet die Tabelle.
   *
   * @param data PageData
   */
  doAfterLoadData(data: PagedModel<Inseminator>) {
    this.onSelect(null);
    this.calculateData(data);
    this.updatePage(data);
    this.tableIsLoading = false;
    if (data.content.length === 1) {
      this.selectedElement = data.content[0];
      this.bestaetigen();
    }
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('number', FilterType.NUMBER),
      new SearchFieldDefinition('firstname', FilterType.STRING),
      new SearchFieldDefinition('lastname', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  getService(): ServerPaginationService<Inseminator> {
    return this.inseminatorService;
  }

  /**
   * {@inheritDoc}
   */
  getSortFields() {
    return this.sortField;
  }

  ngOnInit() {
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.initPagination();
    if (this.dialogData.filterCriteriaResourceList) {
      this.searchTechnikerField.searchField.nativeElement.value =
        this.dialogData.filterCriteriaResourceList[this.dialogData.filterCriteriaResourceList.length - 1].value;
      this.searchFilterPagingResource.orFilterCriteriaResourceList = this.dialogData.filterCriteriaResourceList;
    }
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   *  {@inheritDoc}.
   */
  onDoubleClick(row: Inseminator) {
    this.dialogRef.close(row);
  }

  /**
   * {@inheritDoc}
   */
  rowIdentity(row: Inseminator) {
    return row.technikerKey.number;
  }
}
