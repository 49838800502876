import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedModel } from '../../core/filter/paged-model';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Agrarartikel } from '../../model/agrarartikel';

@Injectable({
  providedIn: 'root'
})
export class DienstleistungService {
  private url = '/api/v1/agrarartikel';

  constructor(private http: HttpClient) {}

  /**
   * Liefert alle fuer den angemeldeten Benutzer sichtbaren Artikel.
   *
   * @return Observable<Array<Agrarartikel>>
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<PagedModel<Agrarartikel>> {
    return this.http.post<PagedModel<Agrarartikel>>(this.url + '/vostdienstleistungen', searchFilterPagingResource);
  }
}
