import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedModel } from '../../core/filter/paged-model';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Cattle } from '../../model/cattle';
import { CattleKey } from '../../model/cattleKey';
import { ServerPaginationService } from '../serverPaginationService';

@Injectable({
  providedIn: 'root'
})
export class CattleService implements ServerPaginationService<Cattle> {
  private url = '/api/v1/cattle';

  constructor(private http: HttpClient) {}

  /**
   * Prueft, ob es Betriebe mit der LOM des uebergebenen Tieres gibt.
   * Falls das Tier existiert und nur der Status aktiviert wird, wird dieses Tier zurueckgeliefert,
   * sonst wird null geliefert.
   *
   * @param cattle Cattle
   */
  checkLom(cattle: Cattle): Observable<Cattle> {
    return this.http.post<Cattle>(this.url + '/checkLom', cattle);
  }

  /**
   * Erstellt ein neues Tier.
   *
   * @param cattle Tier
   */
  create(cattle: Cattle): Observable<void> {
    return this.http.post<void>(this.url, cattle);
  }

  /**
   * Liefert die Liste von Cattle, die zu ein Betrieb gehoeren.
   * @param betriebsschluessel Betriebsschluessel
   * @return Liste von Cattle
   */
  findCattleByBetriebsschluessel(betriebsschluessel: string): Observable<Array<Cattle>> {
    return this.http.post<Array<Cattle>>(this.url + '/listPage/' + betriebsschluessel, null);
  }

  /**
   * Liefert den Cattle fuer den angegebenen Cattlekey.
   * @param cattleKey CattleKey
   *
   * @return Cattle
   */
  loadByCattleKey(cattleKey: CattleKey): Observable<any> {
    const params = new HttpHeaders().set('lom', cattleKey.lom).set('betriebsschluessel', cattleKey.betriebsschluessel);
    return this.http.get(this.url + '/cattle', {
      headers: params
    });
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource die Tierliste.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<PagedModel<Cattle>>
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<PagedModel<Cattle>> {
    return this.http.post<PagedModel<Cattle>>(this.url + '/listPage', searchFilterPagingResource);
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource und eingegebenen Betriebsschluessel die Tierliste.
   *
   * @param pathVariables Betriebsschluessel
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @param status Status der Kuh (optional)
   * @return Observable<PagedModel<Cattle>>
   */
  loadDataWithPath(pathVariables: string[], searchFilterPagingResource: SearchFilterPagingResource, status?: string): Observable<PagedModel<Cattle>> {
    if (status) {
      return this.http.post<PagedModel<Cattle>>(this.url + '/listPageBy/' + pathVariables.join('/'), searchFilterPagingResource, {
        headers: new HttpHeaders().set('status', status)
      });
    }
    return this.http.post<PagedModel<Cattle>>(this.url + '/listPageBy/' + pathVariables.join('/'), searchFilterPagingResource);
  }
}
