import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedModel } from '../../core/filter/paged-model';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Cattle } from '../../model/cattle';
import { Farm } from '../../model/farm';
import { ServerPaginationService } from '../serverPaginationService';

@Injectable({
  providedIn: 'root'
})
export class FarmService implements ServerPaginationService<Farm> {
  private url = '/api/v1/farm';

  constructor(private http: HttpClient) {}

  /**
   * Setzt den Suchfokus fuer einen Betriebsschluessel fuer den angemeldeten Techniker.
   *
   * @param betriebsschluessel Betriebsschluessel
   * @param suchfokus Suchfokus
   */
  changeSuchfokus(betriebsschluessel: string, suchfokus: string): Observable<void> {
    const params = new HttpHeaders().set('suchfokus', suchfokus).set('betriebsschluessel', betriebsschluessel);
    return this.http.get<void>(this.url + '/changeSuchfokus', {
      headers: params
    });
  }

  /**
   * Setzt den angemeldeten Benutzer zu den Betrieben mit den uebergebenen Betriebsschluesseln oder entfernt diesen.
   *
   * @param betriebsschluessel Array<string>
   */
  changeVertretung(betriebsschluessel: Array<string>): Observable<void> {
    return this.http.put<void>(this.url + '/changeVertretung', betriebsschluessel);
  }

  /**
   * Liefert die Betriebe bei denen der Techniker Vertreter ist.
   */
  findAllVertreterbetriebe(): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.url + `/findAllVertreterbetriebe`);
  }

  /**
   * Gibt mit dem Betriebsschluessel den Betrieb zurueck.
   *
   * @param betriebsschluessel Betriebsschluessel
   */
  loadByBetriebsschluessel(betriebsschluessel) {
    const params = new HttpHeaders().set('betriebsschluessel', [betriebsschluessel]);
    return this.http.get<Farm>(this.url + `/loadByBetriebsschluessel`, { headers: params });
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource die Betriebe.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<PagedModel<Farm>>
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<PagedModel<Farm>> {
    return this.http.post<PagedModel<Farm>>(this.url + '/listPage', searchFilterPagingResource);
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource die Betriebe.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<PagedModel<Cattle>>
   */
  loadDataForUser(searchFilterPagingResource: SearchFilterPagingResource): Observable<PagedModel<Farm>> {
    return this.http.post<PagedModel<Farm>>(this.url + '/listPageForUser', searchFilterPagingResource);
  }

  /**
   * Liefert die Betriebsschluessel bei denen die Vertretung true ist.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<PagedModel<Farm>>
   */
  loadDataIsVertreter(searchFilterPagingResource: SearchFilterPagingResource): Observable<PagedModel<Farm>> {
    return this.http.post<PagedModel<Farm>>(this.url + '/listBetriebsVertretung', searchFilterPagingResource);
  }

  /**
   * Entfernt die Vertretung von den manuell zugewiesenen Betrieben.
   * @return Observable
   */
  resetAllManualVertretung(): Observable<void> {
    return this.http.put<void>(this.url + '/resetAllManualVertretung', null);
  }

  /**
   * Aktualisiert einen bestehenden Betrieb.
   *
   * @param farm Farm
   */
  update(farm: Farm): Observable<Farm> {
    return this.http.put<Farm>(this.url, farm);
  }
}
